import axios from "axios";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DecryptToken } from "../../../api/helpers/decrypt";

const token = DecryptToken();

const getOrderDetail = async (id) => {

    const api = new Promise((resolve, reject) => {

        try{
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/order/${id}/`,
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error)
            });
        }
        catch (error){
            console.error(error);
        };
  });

    try {
        const response = await api; // Wait for the Promise to be resolved.
            return response
      } catch (error) {
            return error; // Handle the error appropriately or return a default value.
    }
};

// Create an async function that fetches the data from the API
const fetchOrderDetail = async (id) => {
    const data = await getOrderDetail(id);
    return data;
  }

// Step 3: Use createAsyncThunk to define an action that handles the async operation
export const fetchOrderDetailAsync = createAsyncThunk(
    'orderDetail/fetchOrderDetail',
    async (id) => {
        const data = await fetchOrderDetail(id);
        return data;
    }
);


const orderDetailSlice = createSlice({
    name: 'orderDetail',
    initialState: {
        datas: [], // Initialize with an empty array
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderDetailAsync.pending, (state) => {
            // Handle loading state if needed
            })
            .addCase(fetchOrderDetailAsync.fulfilled, (state, action) => {
            // Handle successful http response
            state.datas = action.payload;
            })
            .addCase(fetchOrderDetailAsync.rejected, (state, action) => {
            // Handle error
            })
    }
});


export default orderDetailSlice.reducer;

// export const { } = orderDetailSlice.actions;

export const initializeOrderDetail = (id) => async (dispatch) => {
    try {
        await dispatch(fetchOrderDetailAsync(id));
    }
    catch (error) {
        console.error(error);
    }
};

