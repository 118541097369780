import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();

const CreateUser = async (data) => {
    // create new user with given data

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/auth/register/`,
            data: data,
            headers: {
                'Authorization': token
            }
        })
        .then((response) => {
            console.log(response)
            resolve(response);
        })
        .catch((error) => {
            console.log(error)
            reject(error);
        }); 
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

export { CreateUser };