import React, {useState, useEffect} from "react";
import {Card, CardBody, CardHeader,Col, Row, Table} from "reactstrap";
import { getFilteredFlaw } from "../../api/flaw/Flaw";

const FlawType = (props) => {
    const {flawWColor, roll, order_id, setFlaw, setFlawFilterToSession, setPage} = props;
    const [selectedRow, setSelectedRow] = useState(null);


    const setFlawFilterActiveToSession = () => {
        // set active status to session for manipulate flaw table page better.
        sessionStorage.setItem("flaw_filter", JSON.stringify(true));
        setTimeout(() => sessionStorage.setItem('flaw_filter', JSON.stringify(false)), 1000);
    };

    const handleFlawType = async (flawType) => {
        // get filtered flaws from backend
        let data;  // filter data as global scope
        if (flawType === false) {
            // if there is no flaw type, that mean is we do not want to filter anymore. So fetch all.
            data = {
                roll: String(roll.id),
                order: String(order_id),
                limit: 100000
            }
        }
        else {
            // create filter json for current order, roll and flaw type with huge limit
            data = {
                roll: String(roll.id),
                order: String(order_id),
                flaw_type_code: String(flawType),
                limit: 100000
            }
        }

        // fetch filtered flaws from backend
        const response = await getFilteredFlaw(data);

        if (response.request.status === 200) {
            setPage(1);  // set table current page as 1
            setFlawFilterToSession(flawType);  // set filter info to sessionStorage
            // set new data to state, but do not change count info because its general count of order
            setFlaw((prevFlaw) => {
                return {
                    count: prevFlaw.count,
                    results: response.data.results
                }
            });
            setFlawFilterActiveToSession();  // set filter as active
        }
        else {
            console.error("Unable to find flaw type");
            // you can give trouble alert here!
        };
    };

    const handleClick = (e, key, isRollChange) => {
        const allRows = Array.from(e.target.closest('tbody').children);

        // Eğer halihazırda seçili olan elemana tıklanıyorsa, tüm elementleri varsayılan ayarlarına döndür
        if (selectedRow === e.target.closest('tr') || isRollChange) {
            allRows.forEach(row => {
                row.style.opacity = '1';
                row.querySelector('td').style.backgroundColor = '#f9f9f95c';
            });
            setSelectedRow(null); // Seçili öğeyi sıfırla
            handleFlawType(false)
            return;
        }

        // Tüm satırların opacity'sini ve arka plan rengini varsayılan ayarlarına döndür
        allRows.forEach(row => {
            row.style.opacity = '1';
            row.querySelector('td').style.backgroundColor = '#f9f9f95c';
        });

        // Tıklanan öğenin arka plan rengini değiştir ve diğer öğelerin opacity'sini yarıya indir
        e.target.parentElement.style.backgroundColor = '#000000c2';
        allRows.forEach(row => {
            if (row !== e.target.closest('tr')) {
                row.style.opacity = '0.5';
            }
        });

        setSelectedRow(e.target.closest('tr')); // Yeni seçili öğeyi ayarla
        handleFlawType(flawWColor[key].id);
    };

    useEffect(() => {
        // Tüm satırların opacity'sini ve arka plan rengini varsayılan ayarlarına döndür
        const allRows = Array.from(document.querySelector('tbody').children);
        allRows.forEach(row => {
            row.style.opacity = '1';
            row.querySelector('td').style.backgroundColor = '#f9f9f95c';
        });
    }, [roll])


    return (
        <Card>
            <CardHeader>
                <h4 className="card-title">{props.t('flawType')}</h4>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>

                        <Table className="table-centered align-middle table-nowrap mb-0" style={{border: '1px solid #bfbfbf73'}}>
                            <thead className="text-center " style={{background: '#00425a'}}>
                                <tr>
                                    <th className="text-uppercase text-light p-2">{props.t('flaw')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { flawWColor && Object.keys(flawWColor).map((key) => {
                                    return (
                                        <tr>
                                            <td className='text-center align-items-center px-4' 
                                                key={key}
                                                style={{transition: 'all 0.5s ease-in-out', backgroundColor: '#f9f9f95c'}}
                                                
                                                > 
                                                <span className="d-flex justify-content-center"
                                                        style={{
                                                            border: '0', backgroundColor: `${flawWColor[key].color}`, 
                                                            color: 'white', fontWeight: 'bold', padding: '5px',
                                                            transition: 'all 0.5s ease-in-out',
                                                            cursor: 'pointer'
                                                            }}
                                                        onMouseEnter={(e) => {
                                                            e.target.style.color = 'black';
                                                            // You can add other styles for the hover effect here
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.target.style.color = 'white';
                                                        }}
                                                        onClick={(e) => handleClick(e, key)}
                                                    >
                                                    {flawWColor[key].name}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                    })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>

    )
}

export default FlawType;