import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Label,
    Modal, ModalBody, ModalHeader,
    Row,
} from 'reactstrap';
import {PartyFilter, FlawTypeFilter} from './PartySearch';
import DatePickerMaterialUI from './DatePicker';
import {getFilteredFlaw, getFlawDetails, FlawTypeDropdown} from "../../api/flaw/Flaw";
import ReportTable from "./ReportTable";
import {convertDate} from "../../Components/Common/helper";
import ExcelReport from "../../api/report/Report";
import { useTranslation } from 'react-i18next';
import {useSearchParams} from "react-router-dom";

const ReportPanel = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [partyNo, setPartyNo] = useState(null);
    const [flawType, setFlawType] = useState(null);
    const [flawTypeInfo, setFlawTypeInfo] = useState(null);
    const [flawDetail, setFlawDetail] = useState(null);
    const [modal, setModal] = useState(false);

    const [reportData, setReportData] = useState(null);
    const [clearTable, setClearTable] = useState(false);
    const { t } = useTranslation(['reportPanel']); // Using the 'reportPanel' namespace

    useEffect(() => {
        // get flaw type dropdown
        FlawTypeDropdown().then((response) => {
            setFlawTypeInfo(response.result);
        });
    }, []);

    useEffect(() => {
        if (flawTypeInfo) {
            const typeCode = sessionStorage.getItem('flaw_type_code')
            if (typeCode) {
                const params = {flaw_type_code: typeCode, limit: 7};
                fetchFlaw(params)
            }
        }
    }, [flawTypeInfo]);


    const getFlawType = (flaw_type_id) => {
        // get flaw type name from flaw type id
        let flawType;
        flawTypeInfo.map((flaw) => {
            if (flaw[0] === flaw_type_id) {
                flawType = flaw[1];
            }
        });
        return flawType;
    };

    const fetchFlaw = async (params, url) => {
        // fetch filtered flaw from backend
        let response;
        if (url) {
            response = await getFilteredFlaw(undefined, url);
        }
        else {
            response = await getFilteredFlaw(params, undefined);
        }
        if (response.request.status === 200) {
            setReportData(response.data);

        };
    };

    const getParams = () => {
        const params = {}
        params['order'] = partyNo && (partyNo.map((item) => parseInt(item.value))).join(',')
        params['flaw_type_code'] = flawType && (flawType.map((item) => parseInt(item.value))).join(',')
        params['created_at_after'] = startDate && startDate;
        params['created_at_before'] = endDate && endDate;
        params['limit'] = 7;
        return params;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // get filter parameters
        const params = getParams();
        setClearTable(true);
        setTimeout(() => {
            setClearTable(false);
        }, 1000)
        // fetch flaws
        await fetchFlaw(params, undefined);
        sessionStorage.removeItem('flaw_type_code')  // remove flaw type code from session storage
    };

    const changePage = async (e, rotation) => {
        e.preventDefault();
        // change page according to income rotate (next, previous)
        const url = reportData[rotation]

        // fetch flaws
        await fetchFlaw(undefined, url);
    }

    const toggle = (flaw_id) => {
        // flaw detail modal toggle
        // when we close the modal, it returns event object
        // this cause to error in getFlawDetails function
        // so we check the type of flaw_id
        if (typeof(flaw_id) === 'number'){
            getFlawDetails(flaw_id).then((response) => {
                setFlawDetail(response.data);
                setModal(!modal);
            });
        }
        else {
            setModal(!modal);
        };
    };

    const getPartyNo = (orderId) => {
        // get party no by order id from state
        if (!partyNo) return orderId;
        const data = partyNo.filter((item) => (
            parseInt(item.value) === parseInt(orderId)
        ))
        return data.length > 0 ? data[0].label : orderId;
    };


    const searchTable = reportData && reportData.results.map((item) => {
        //console.log(item.image)
        const flaws = {
            "id": item.id,
            "image": item.image,
            "flaw_type": getFlawType(item.flaw_type_code),
            // "roll": item.roll,
            "order": getPartyNo(item.order),
            "flaw_location": (item.millimeter) / 1000,
            "flaw_width": item.flaw_size.width,
            "flaw_height": item.flaw_size.height,
            "created_at": convertDate(item.created_at)
        }
        return flaws;
    });

    const columns = useMemo(() => [
    {
        Header: t('flawImage'),
        accessor: 'image',
        Cell: ({ cell: { value }, row: { original } }) => (
            <img
              src={value}
              alt="flaw_image"
              loading='lazy'
              style={{ maxWidth: '48px', maxHeight: '48px', height: '48px', cursor: 'pointer' }}
              onClick={() => toggle(original.id)}
            />
          ),
    },
    {
        Header: t('flawType'),
        accessor: 'flaw_type',
        Cell: ({ cell: { value }, row: { original } }) => {
            return (
                <span>{value}</span>
            )
        }
    },
    { Header: t('partyNo'), accessor: 'order' },
    // { Header: 'Top', accessor: 'roll' },
    { Header: t('flawPlace') + ' (' + t('shortMeter') + ')', accessor: 'flaw_location' },
    { Header: t('flawWidth') + ' (' + t('shortMillimeter') + ')', accessor: 'flaw_width' },
    { Header: t('flawHeight') + ' (' + t('shortMillimeter') + ')', accessor: 'flaw_height' },
    { Header: t('createdAt'), accessor: 'created_at' },
  ], [t]);

    const handleDateSelect = (e, func) => {
        /* when a datetime selected, convert this to ISO string
        params: 
            e -> date data
            func -> state handler function
        */
        const date = new Date(e);
        const ISOformat = date.toISOString();
        func(ISOformat);
    }

    const handleExcelReport = async (e) => {
        e.preventDefault();

        // Assuming getParams() returns an object with keys and values
        const params = getParams();
        // change created_at_after as created_at

        // Remove 'limit' key and filter out null values
        const filteredParams = Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'limit' && value !== null )
        );

        try {
            const response = await ExcelReport(filteredParams)
            // Create a Blob from the response data
            const blob = new Blob([response.data], {
                type: response.headers['content-type'], // Get the content type from response headers
            });

            // Create a link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'report.xlsx');

            // Append link to the body
            document.body.appendChild(link);

            // Trigger click event to start download
            link.click();

            // Remove the link element
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        }

        // console.log(param)

        // window.location.href=`${process.env.REACT_APP_API_URL}/report/?${param}`;
    }

    return (
        <div className="page-content ">
                <Container fluid>
                    {/* MODALS */}
                    <Modal isOpen={modal}
                        toggle={toggle}
                        centered={true}
                        size={"xl"}>
                        <ModalHeader toggle={toggle} className="px-5 ms-3 text-uppercase">
                            <h4>{t('flawDetails')}</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-6" style={{ display: 'flex', justifyContent:'center', alignItems: 'center'}} >
                                    { flawDetail && flawDetail.flaw_coordinate &&
                                        <div >
                                            <img id={`flaw_image_${flawDetail.id}`}
                                                src={modal && `${flawDetail.image}`} alt="flaw_image"
                                                loading="lazy"
                                                style={{
                                                        width: '100%',
                                                        maxWidth: '100%',
                                                        borderRadius: '3px'
                                                }}
                                            />
                                        </div>
                                    }

                                </div>
                                <div className="col-md-6 text-center mt-4 fs-5">
                                    <div className="row pt-3">
                                        <div className="col-md-6 text-start">
                                            <p className='fs-5'><b>{t('flawType')}:</b></p>
                                        </div>
                                        <div className="col-md-6 text-start">
                                            <p className='fw-bold fs-5'>{flawDetail && getFlawType(flawDetail.flaw_type_code)}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-start">
                                            <p className='fs-5'><b>{t('flawWidth')}:</b></p>
                                        </div>
                                        <div className="col-md-6 text-start">
                                            <p className='fw-bold'>{modal && flawDetail.flaw_size.width} <span className='text-muted'>{t('millimeter')}</span></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-start">
                                            <p className='fs-5'><b>{t('flawHeight')}:</b></p>
                                        </div>
                                        <div className="col-md-6 text-start">
                                            <p className='fw-bold'>{modal && flawDetail.flaw_size.height} <span className='text-muted'>{t('millimeter')}</span></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-start">
                                            <p className='fs-5'><b>{t('flawPlace')}:</b></p>
                                        </div>
                                        <div className="col-md-6 text-start">
                                            <p className='fw-bold'>{modal && flawDetail.millimeter/1000} <span className='text-muted'>{t('meter')}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Row>
                        <Col lg="3">
                            <Card>
                                <CardHeader className='pb-0'>
                                    <h4 className="card-title mb-0">{t('filters')}</h4>
                                </CardHeader>
                                <hr></hr>
                                <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    {/* PARTY FILTER SELECT */}
                                    <div className=''>
                                        <Label htmlFor="party_filter">{t('partyNo')}</Label>
                                        <PartyFilter id="part_filter" setPartyNo={setPartyNo} t={t}/>
                                    </div>


                                    {/* FLAW TYPE FILTER SELECT */}
                                    <div className='mt-4'>
                                        <Label htmlFor="flaw_filter">{t('flawType')}</Label>
                                        <FlawTypeFilter
                                            id="flaw_filter"
                                            setFlawType={setFlawType} t={t}
                                            // defaultValue={paramsData && paramsData}
                                        />
                                    </div>

                                    {/* TIME RANGE FILTER */}
                                    <Label className='mt-4'>{t('dateRange')}</Label>
                                    <Row className='mt-1'>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <DatePickerMaterialUI label={t('startDate')} handleSelect={handleDateSelect} func={setStartDate}/>
                                        </Col>

                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <DatePickerMaterialUI label={t('endDate')} handleSelect={handleDateSelect} func={setEndDate}/>
                                        </Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Button className='w-100' color='primary' type='submit'>{t('preview')}</Button>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Button className='w-100' color='success' type='button'
                                                    onClick={handleExcelReport}>{t('excelReport')}</Button>
                                        </Col>
                                    </Row>
                                </Form>



                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="9">
                            <Card>
                                <CardHeader className='pb-0'>
                                    <h4 className="card-title mb-0">{t('reports')}</h4>
                                </CardHeader>
                                <hr></hr>
                                <CardBody className="mt-0 pt-0">
                                    {
                                        searchTable ?
                                            <ReportTable
                                                searchTable={searchTable}
                                                columns={columns}
                                                reportData={reportData}
                                                pageSize={7}
                                                changePage={changePage}
                                                clearTable={clearTable}
                                                t={t}
                                            />
                                        :
                                        <div className="text-muted text-center" style={{
                                            width: '100%',
                                            paddingTop: '0',
                                            fontSize: '24px',
                                            fontWeight: 'bolder',
                                        }}>
                                            {t('previewWarning')}
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                </Container>
        </div>
    )
};


export default ReportPanel;