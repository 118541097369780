import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledAlert
} from 'reactstrap';
import classnames from "classnames";


import avatar1 from '../../assets/images/users/avatar-1.jpg';
import { GetUser } from '../../api/GetUser';
import { FlawTypeDropdown } from '../../api/flaw/Flaw';
import {
    getCompanyPreference, updateDefectSizePreference,
    updateDefectFilterPreference, updateDefectPointPreference,
    updateReportPreference} from '../../api/preference/Preference';
import { isEmptyArray } from 'formik';
import {useTranslation} from "react-i18next";
import Select from "react-select";

const Settings = () => {
    document.title = "Admin panel";

    const [activeTab, setActiveTab] = useState("3");
    const [user, setUser] = useState([]);
    const [flawType, setFlawType] = useState([]);
    const [preference, setPreference] = useState(null);
    const [checkedItems, setCheckedItems] = useState({});
    const [defectSize, setDefectSize] = useState({});
    const [defectPoint, setDefectPoint] = useState({})
    const [reportStandard, setReportStandard] = useState(null);
    const [notification, setNotification] = useState(null);
    const [open, setOpen] = useState(null);
    const { t } = useTranslation('settings'); // Using the 'reportPanel' namespace

    const toggle = (id) => {
        // toggle accordion action
        if (open === id) {
            // if open accordion item clicked again, close it
            setOpen(null);
        } else {
            setOpen(id);
        };
    };

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const fetchFlawType = async ()  => {
        // fetch flaw type from backend and set to state
        const response = await FlawTypeDropdown();
        if (response.result) {
            setFlawType(response.result);
        };
    };

    const fillCheckbox = () => {
        // when flaw type filters loaded 
        // check flaw type filters status and set to state
        preference && Object.keys(preference.defect_filter).forEach((key) => {
            const value = preference.defect_filter[key];
            setCheckedItems(prevState => ({
                ...prevState,
                [key]: value,
            }));
        });
    };

    const fillSize = () => {
        // when flaw type filters loaded 
        // check flaw type filters status and set to state
        preference && Object.keys(preference.defect_size).forEach((key) => {
            const value = preference.defect_size[key];
            setDefectSize(prevState => ({
                ...prevState,
                [key]: value ? value : [null, null],
            }));
        });
    }

    const fillPoint = () => {
        // when flaw type filters loaded
        // check flaw type filters status and set to state
        preference && Object.keys(preference.defect_point).forEach((key) => {
            const value = preference.defect_point[key];
            setDefectPoint(prevState => ({
                ...prevState,
                [key]: value ? value : null,
            }));
        });
    }


    const fetchPreference = async () => {
        // fetch company preference from backend and set to state
        const response = await getCompanyPreference();
        if (response.request.status === 200) {
            // every company should have a config 
            // so just get first one from response
            setPreference(response.data[0])
        };
    };

    const getFlawName = (defectCode) => {
        // get flaw type name according to flaw type code
        let name;
        !isEmptyArray(flawType) && flawType.forEach(typeItem => {
            if (typeItem[0] === parseInt(defectCode) ){
                name = typeItem[1]
            }
        });
        return name;
    };

    const fetchUser = async () => {
        // fetch users from backend
        const response = await GetUser();
        if (response.request.status === 200) {
            setUser(response.data);
        };
    };

    useEffect(() => {
        // Fetch required thing from backend
        fetchUser();
        fetchFlawType(); 
        fetchPreference();
    }, [])

    useEffect(() => {
        fillCheckbox();
        fillSize();
        fillPoint();
        preference && setReportStandard(preference.report_standard);
    }, [preference]);

    const options = [
        {key:"FO", label:"4 Point" },
        {key:"TE", label:"10 Point" },
        {key:"DA", label:"Dallas Standard" },
    ]

    const getReportStandard = () => {
        // get report standard according to report standard key
        const report = options.filter(option => option.key === reportStandard)
        return report;
    };

    const handleReportSubmit = async (e) => {
        // update report standard preference
        e.preventDefault();
        const preferenceId = preference.id;
        const response = await updateReportPreference(preferenceId, reportStandard);

        if (response.request.status === 200) {
            // update state
            setPreference(response.data);
            // show notification
            setNotification({
                message: t('reportStandardSuccessMsg'),
                type: 'success'
            });
            // hide notification after 4 seconds
            setTimeout(() => {
                setNotification(null);
            }, 4000)
        }

    }
    const handleDefectFilterSubmit = async (e) => {
        e.preventDefault();
        // get state data instead of form data because they are same
        const defectFilter = checkedItems;
        const preferenceId = preference.id;

        // update company size preference 
        const response = await updateDefectFilterPreference(preferenceId, defectFilter);
        if (response.request.status === 200) {
            // update state
            setPreference(response.data);
            // show notification
            setNotification({
                message: t('flawTypeFilterSuccessMsg'),
                type: 'success'
            });
            // hide notification after 4 seconds
            setTimeout(() => {
                setNotification(null);
            }, 4000)
        }
    };
    const handleDefectSizeSubmit = async (e) => {
        e.preventDefault();
        // get state data instead of form data because they are same
        const data = defectSize;
        const preferenceId = preference.id;

        // update company size preference 
        const response = await updateDefectSizePreference(preferenceId, data);
        if (response.request.status === 200) {
            // update state
            setPreference(response.data);

             // show notification
             setNotification({
                 message: t('flawTypeSizeSuccessMsg'),
                 type: 'success'
             });
             // hide notification after 4 seconds
             setTimeout(() => {
                 setNotification(null);
             }, 4000)
        };
        
    };
    const handlePointSubmit = async (e) => {
        e.preventDefault();
        // get state data instead of form data because they are same
        const data = defectPoint;
        const preferenceId = preference.id;

        // update company size preference
        const response = await updateDefectPointPreference(preferenceId, data);
        if (response.request.status === 200) {
            // update state
            setPreference(response.data);

             // show notification
             setNotification({
                 message: t('flawPointMsg'),
                 type: 'success'
             });
             // hide notification after 4 seconds
             setTimeout(() => {
                 setNotification(null);
             }, 4000)
        };
    }
    const handlePoint = (e) => {
        const defectCode = e.target.id;
        const defectPoint = e.target.value;
        const defectSize = e.target.name;

        setDefectPoint((prevState) => {
            // Create a new state object based on the previous state
            const newState = { ...prevState };

            // Initialize the defect code if it doesn't exist in the state
            if (!newState[defectCode]) {
                newState[defectCode] = {};
            }

            // Handle the conditional logic
            if (defectPoint === "") {
                // If defectSize is an empty string, remove the key from the defect code object
                delete newState[defectCode][defectSize];

                if (Object.keys(newState[defectCode]).length === 0) {
                    // If empty, set defectCode to null
                    newState[defectCode] = null;
                }
            } else {
                // Otherwise, update the defect size with the new defect point
                newState[defectCode][defectSize] = parseInt(defectPoint);
            }

            return newState;
        });
    }

    return (
            <div className="page-content mt-5">
                <Container fluid>
                    <Row>
                        <Col xl={3} xxl={2}>
                            <Card className="mt-n5">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img src={avatar1}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile" />
                                        </div>
                                        <h5 className="fs-5 mb-1 text-uppercase">{ user && user.username}</h5>
                                        <p className="text-muted mb-0 text-uppercase">{user.status}</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={9} xxl={10}>
                            <Card className="mt-xxl-n5 p-3">
                                <CardHeader>
                                    {
                                        notification &&
                                        <UncontrolledAlert 
                                            color={notification.type}
                                            style={{position: 'absolute', right: '3%', left: '48%', 
                                                    textAlign:'center', zIndex: '666'
                                            }}>
                                            {notification.message}
                                        </UncontrolledAlert>
                                    }
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "3" })}
                                                onClick={() => {
                                                    tabChange("3");
                                                }}
                                                type="button"
                                                style={{
                                                    color: activeTab === '3' ? 'dodgerblue' : 'black',
                                                    border: activeTab === '3' ? "1px solid dodgerblue" : "none",
                                                    borderRadius: "5px"
                                                }}>
                                                <i className=" bx bx-user me-2"></i>
                                                {t('companySettings')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "4" })}
                                                onClick={() => {
                                                    tabChange("4");
                                                }}
                                                type="button"
                                                style={{
                                                    color: activeTab === '4' ? 'dodgerblue' : 'black',
                                                    border: activeTab === '4' ? "1px solid dodgerblue" : "none",
                                                    borderRadius: "5px"
                                                }}>
                                                <i className=" bx bx-user me-2"></i>
                                                {t('flawPoints')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <TabContent activeTab={activeTab}>
                                        {/* COMPANY PREFERENCES TAB */}
                                        <TabPane tabId="3">
                                             <Row className="ps-3 mt-3 justify-content-start ">
                                                 <Col xs={12} sm={12} md={12} lg={4} xl={3}
                                                      style={{border: '1px solid #1e90ff40', borderRadius: '5px'}}>
                                                     <div className="mt-4 mb-1 border-bottom p-2">
                                                         <h5 className="card-title mb-4">{t('reportStandard')}</h5>
                                                         <Form onSubmit={handleReportSubmit}>
                                                             <Select options={options} isDisabled={false}
                                                                     onChange={(e) => setReportStandard(e.key)}
                                                                 value={reportStandard && getReportStandard()}/>
                                                             <div className="mt-3">
                                                                 <Button className="btn btn-soft-warning w-100"
                                                                         type='submit'>
                                                                     {t('apply')}
                                                                 </Button>
                                                             </div>
                                                         </Form>
                                                     </div>
                                                 </Col>
                                             </Row>
                                            <Row className='gap-2 ps-3 mt-2 justify-content-start'>
                                                <Col xs={12} sm={12} md={12} lg={3} xl={3}
                                                     style={{border: '1px solid #1e90ff40', borderRadius: '5px'}}>
                                                    <div className="mt-4 mb-1 border-bottom p-2">
                                                        <h5 className="card-title mb-4">{t('flawTypeFilter')}</h5>

                                                        <Form onSubmit={handleDefectFilterSubmit}>
                                                            { !isEmptyArray(flawType) && preference && Object.entries(preference.defect_filter).map(([key, value]) => (
                                                                <Row className='ms-1'>
                                                                        <div className="form-check form-switch ">
                                                                            <Input class="form-check-input"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={key}
                                                                                checked={checkedItems && checkedItems[key]} // set checked attribute to the state value
                                                                                onChange={(e) => {
                                                                                    setCheckedItems({
                                                                                        ...checkedItems,
                                                                                        [key]: e.target.checked,
                                                                                    });
                                                                                }}
                                                                                style={{border: '1px solid rgb(206, 212, 218)'}}/>
                                                                            <Label class="form-check-label" for={key}>
                                                                                { getFlawName(key)}
                                                                            </Label>
                                                                        </div>
                                                                    </Row>
                                                            ))}
                                                            <div className="mt-3">
                                                                <Button className="btn btn-soft-secondary w-100" type='submit'>
                                                                    {t('apply')}
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </Col>

                                                <Col xs={12} sm={12} md={12} lg={8} xl={8}
                                                     style={{border: '1px solid #1e90ff40', borderRadius: '5px'}}>
                                                    <div className="mt-4 mb-1 border-bottom p-2">
                                                        <h5 className="card-title mb-2">{t('minFlawTypeSize')}</h5>

                                                        <div className='d-flex align-items-center mb-3 text-muted'>
                                                            <i className='bx bxs-error me-2 text-danger fs-6'></i>
                                                            {t('minFlawTypeWarning')}
                                                        </div>

                                                        <Form onSubmit={handleDefectSizeSubmit}>
                                                            <Row>
                                                            { !isEmptyArray(flawType) && preference && Object.entries(preference.defect_size).map(([key, value]) => (
                                                                <Col md={3}className='mb-3'>
                                                                    <div>
                                                                        <Label htmlFor={'defect_' + key}
                                                                            className="form-label">
                                                                            {getFlawName(key)} | <span className='text-muted'>mm</span>
                                                                        </Label>
                                                                        <div className='input-group input-group-sm'>
                                                                            <div class="form-floating">
                                                                                <Input type="number" className="form-control"
                                                                                id={'defect_' + key + '_width'}
                                                                                style={{ border: "1px solid #ced4da"}}
                                                                                step="0.01"
                                                                                placeholder="Mininum hata eni"
                                                                                value={defectSize && defectSize[key] && defectSize[key][0]}

                                                                                onChange={(e) => {
                                                                                    const newValue = e.target.value? e.target.value : null;
                                                                                    setDefectSize({
                                                                                        ...defectSize,
                                                                                        [key]: [newValue, defectSize[key][1]], // update the first element of the array
                                                                                    });
                                                                                }}/>
                                                                                <Label htmlFor={'defect_' + key + '_width'}>{t('width')}</Label>
                                                                            </div>
                                                                            <div class="form-floating">
                                                                                <Input type="number" className="form-control"
                                                                                id={'defect_' + key + '_height'}
                                                                                style={{ border: "1px solid #ced4da"}}
                                                                                step="0.01"
                                                                                placeholder="Mininum hata boyu"
                                                                                value={defectSize && defectSize[key] && defectSize[key][1]}
                                                                                onChange={(e) => {
                                                                                    const newValue = e.target.value? e.target.value : null;
                                                                                    setDefectSize({
                                                                                        ...defectSize,
                                                                                        [key]: [defectSize[key][0], newValue], // update the first element of the array
                                                                                    });
                                                                                }}/>
                                                                                <Label htmlFor={'defect_' + key + '_height'}>{t('height')}</Label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                            </Row>
                                                            <div >
                                                                <Button className="btn btn-soft-danger w-100">
                                                                    {t('apply')}
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </Col>
                                            </Row>


                                        </TabPane>
                                        {/* END COMPANY PREFERENCES TAB */}

                                        {/* FLAW POINT PRECERENCES TAB */}
                                        <TabPane tabId="4">
                                            <Form onSubmit={handlePointSubmit}>
                                                { !isEmptyArray(flawType) && preference && Object.entries(preference.defect_point).map(([key, value]) => (
                                                <Accordion open={open} toggle={toggle} style={{border: '1px solid rgba(0, 0, 0, 0.07)'}}>
                                                    <AccordionItem>
                                                        <AccordionHeader targetId={key}><b> {getFlawName(key)}</b></AccordionHeader>
                                                        <AccordionBody accordionId={key}>
                                                              <Row className="px-3">
                                                                  <Col md={3}>
                                                                      <FormGroup row className="px-3">
                                                                          <Label for={key}>0-3 mm</Label>
                                                                          <Input
                                                                            id={key}
                                                                            name="0-3"
                                                                            placeholder="Enter"
                                                                            value={defectPoint && defectPoint[key] && defectPoint[key]['0-3']}
                                                                            type="number"
                                                                            min={0}
                                                                            max={4}
                                                                            style={{border: '1px solid rgb(206, 212, 218)'}}
                                                                            onChange={(e) => handlePoint(e) }
                                                                          />
                                                                      </FormGroup>
                                                                  </Col>
                                                                  <Col md={3}>
                                                                      <FormGroup row className="px-3">
                                                                          <Label for={key}>3-6 mm</Label>
                                                                          <Input
                                                                            id={key}
                                                                            name="3-6"
                                                                            placeholder="Enter"
                                                                            value={defectPoint && defectPoint[key] && defectPoint[key]['3-6']}
                                                                            type="number"
                                                                            min={0}
                                                                            max={4}
                                                                            style={{border: '1px solid rgb(206, 212, 218)'}}
                                                                            onChange={(e) => handlePoint(e) }
                                                                          />
                                                                      </FormGroup>
                                                                  </Col>
                                                                  <Col md={3}>
                                                                      <FormGroup row className="px-3">
                                                                          <Label for={key}>6-9 mm</Label>
                                                                          <Input
                                                                            id={key}
                                                                            name="6-9"
                                                                            placeholder="Enter"
                                                                            value={defectPoint && defectPoint[key] && defectPoint[key]['6-9']}
                                                                            type="number"
                                                                            min={0}
                                                                            max={4}
                                                                            style={{border: '1px solid rgb(206, 212, 218)'}}
                                                                            onChange={(e) => handlePoint(e) }
                                                                          />
                                                                      </FormGroup>
                                                                  </Col>
                                                                  <Col md={3}>
                                                                      <FormGroup row className="px-3">
                                                                          <Label for={key}>9+ mm</Label>
                                                                          <Input
                                                                            id={key}
                                                                            name="9+"
                                                                            placeholder="Enter"
                                                                            value={defectPoint && defectPoint[key] && defectPoint[key]['9+']}
                                                                            type="number"
                                                                            min={0}
                                                                            max={4}
                                                                            style={{border: '1px solid rgb(206, 212, 218)'}}
                                                                            onChange={(e) => handlePoint(e) }
                                                                          />
                                                                      </FormGroup>
                                                                  </Col>
                                                                  <Row className="justify-content-end">
                                                                    <Button color="primary" outline className="w-25 ">
                                                                        {t('apply')}
                                                                    </Button>
                                                                </Row>
                                                              </Row>
                                                      </AccordionBody>
                                                    </AccordionItem>
                                                </Accordion>
                                                ))}
                                            </Form>
                                            {/*</Row>*/}
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
    );
};

export default Settings;
