import React from 'react';
import Route from './Routes';
import './assets/scss/themes.scss';

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
    
  );
}

export default App;
