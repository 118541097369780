import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function DatePickerMaterialUI(props) {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker label={props.label} defaultValue={props.date} onChange={(date) => props.handleSelect(date, props.func)} day/>
      </LocalizationProvider>
    </div>
  );
}