import React, {useState, useEffect} from "react";
import {Card, CardBody, Row, Col, CardHeader} from "reactstrap";
import axios from "axios";
import avatar from "../../assets/images/users/avatar-1.jpg";


const Profile = () => {
    const [user, setUser] = useState([]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/auth/user/`,
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            setUser(response.data);
        }).catch((error) => {
            // console.log(error)
        });
    }, []);

    return (
        <div className="page-content">
            <Row className="justify-content-center">
                <Col lg={3}>
                    <Card className="text-center">
                        <CardHeader>
                            <h4 className="card-title">Profile</h4>
                        </CardHeader>
                        <CardBody>
                            <div className="user-photo">
                                <img src={avatar} alt="avatar" className="rounded-circle avatar-lg img-thumbnail" />
                            </div>
                            <div className="username mt-2">
                                <h5 className="text-uppercase fw-bold fs-5">{user.username}</h5>
                            </div>
                            <div className="user-status  text-muted text-uppercase">
                                <small>{user.status}</small>    
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
                    
        </div>
    )
}

export default Profile;