import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledAlert} from 'reactstrap';

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';


const ProfileDropdown = (props) => {

    const { logoutToggle, user, error } = props;

    useEffect(() => {
        AOS.init();

    }, []);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            { error.message && error.message ? (

                <UncontrolledAlert color="danger" className="alert-additional mb-xl-0" 
                    style={{
                        position: 'absolute',
                        top: 10,
                        left: '50%',
                        translate: '-50%'
                    }}>
                    <div className="alert-body">
                        <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                                <i className="ri-alert-line fs-16 align-middle"></i>
                            </div>
                            <div className="flex-grow-1">
                            <h5 className="alert-heading me-5">Something is very wrong!</h5>
                                <p className="mb-0">{error.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className="alert-content">
                        <p className="mb-0 text-center">{error.request.status === 404 ? "Contact with ali.yildirim@tarsierteknoloji.com" : null }</p>
                    </div>
                </UncontrolledAlert>
            ) : null }
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text text-uppercase">{user.username}</span>
                            <span className="d-none d-xl-block ms-1 fs-13 text-muted user-name-sub-text">{user.status}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <h6 className="dropdown-header">{props.t('welcome')} <span className='text-uppercase'>{user.first_name ? user.first_name : user.username}!</span></h6>
                    
                    <DropdownItem href={process.env.PUBLIC_URL + "/settings"}><span
                        className="badge bg-soft-success text-success mt-1 float-end">New</span><i
                            className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle">{props.t('settings')}</span></DropdownItem>
                    
                    <DropdownItem ><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout" onClick={() => logoutToggle()}>{props.t('logout')}</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export {ProfileDropdown};