import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { Link } from "react-router-dom";
import { isEmptyArray } from "formik";

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  SearchPlaceholder,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <CardBody>
        <form>
          <Row className="g-3">
            <Col>
              <div className="search-box me-2 mb-2 d-inline-block col-12" style={{border: '1px solid #80808047', borderRadius: '3px'}}>
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control search /"
                  placeholder={SearchPlaceholder}
                  value={value || ""}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>

    </React.Fragment>
  );
}


const TableContainer = (props) => {
  const {
    columns,
    data,
    count,
    // next,
    // previous,
    changePage,
    pageIndexCustom,
    isPagination,
    isGlobalSearch,
    isGlobalFilter,
    customPageSize,
    machine,
    flaw,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    SearchPlaceholder,
  } = props;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [lastRoll, setLastRoll] = React.useState(null);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: currentPage, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const getFlawFilterStatusFromSession = () => {
      // get flaw filter status from session status to manipulate current page better.
      const condition = sessionStorage.getItem('flaw_filter')
      try {
        return JSON.parse(condition);
      }
      catch (error) {
        return false;
      }
  }

  useEffect(() => {
    const newRoll = data && !isEmptyArray(data) && data[0].roll;
    if (lastRoll !== newRoll || getFlawFilterStatusFromSession()) {
      setLastRoll(newRoll);
      setCurrentPage(0);
    }
  }, [data])

  const canNextPageCustom= () => {
    return (Math.ceil(count) > currentPage+1);
  }

  const canPreviousPageCustom = () => {
    return (Math.ceil(count)  >= currentPage+1 && currentPage > 0);
  }


  const gotoPageCustom = () => {
  //   // change table content -> send request to server for next page
  //   console.log(next)
  }

  const nextPageCustom = () => {
    // change table content -> send request to server for next page
    changePage(true);
    setCurrentPage(currentPage + 1);
  }

  const previousPageCustom = () => {
    // change table content -> send request to server for previous page
    changePage(false)
    setCurrentPage(currentPage - 1);
  }


  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? <span style={{ fontSize: "15px", fontWeight: "600", marginRight: '20px' }}>&#8593;</span> : <span style={{ fontSize: "15px", fontWeight: "600", marginRight: '20px' }}>&#8595;</span>) : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      {(isGlobalSearch || isGlobalFilter) && (
      <Row className="mb-3">
        {isGlobalSearch && (
          <Col md={1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            SearchPlaceholder={SearchPlaceholder}
          />
        )}
      </Row>
      )}


      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass} style={{border: '1px solid #8080802e', textAlign: 'center'}}>
          <thead className={theadClass} style={{backgroundColor: '#00425a', color: '#fff'}}>
            {headerGroups.map((headerGroup) => (
              <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                    <th key={column.id} className={thClass} {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr data-row-id={row.cells[0].row.original.id}>
                    {row.cells.map((cell) => {
                      return (
                        <td 
                          key={cell.id} 
                          {...cell.getCellProps()}
                          >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                    
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

    {isPagination && 
      <Row className="justify-content-md-end justify-content-center align-items-center p-2">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={flaw ? () => { previousPage(); setCurrentPage(currentPage - 1); } : previousPageCustom}
              disabled={flaw && flaw ? !canPreviousPage : !canPreviousPageCustom()}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Sayfa{" "}
          <strong>
            {flaw && flaw ? pageIndex + 1 : pageIndexCustom + 1} of {flaw && flaw ? pageOptions.length : Math.ceil(count)}
          </strong>
        </Col>
        {/* <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            // max={pageOptions.length}
            max={Math.ceil(count)}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col> */}

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button 
              color="primary" 
              onClick={flaw ? () => { nextPage(); setCurrentPage(currentPage + 1); } : nextPageCustom}
              disabled={flaw && flaw ? !canNextPage : !canNextPageCustom()}
            >
              {">"}
            </Button>
          </div>
        </Col>
      </Row>
    }
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;