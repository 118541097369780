import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();


const CreateRoll = async (order_id) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/roll/`,
            headers: {
                'Authorization': token
            },
            data: {
                "order": order_id,
                "meter": 0
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
};

const UpdateRoll = async (roll_id, meter) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                "meter": meter,
                "status": false
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
};


const ActivateRoll = async (roll_id) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                "status": true,
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
};


const DeactivateRoll = async (roll_id) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                "status": false,
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
};


const GetRollFlaw = async (roll_id, url=null) => {
    /**
     * get roll's flaw
    */
    const next = url ? url : `${process.env.REACT_APP_API_URL}/roll/${roll_id}/flaws/`;
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: next,
            headers: {
                'Authorization': token
            },
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
};

const DeleteRoll = async (roll_id) => {
    /*
        * Delete roll request to backend function
    */
   const api = new Promise((resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/roll/${roll_id}/`,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
   });

   try {
        const response = await api;
        return response;
   }
   catch (error) {
        return error;
   };
};





export {CreateRoll, UpdateRoll, ActivateRoll, DeactivateRoll, GetRollFlaw, DeleteRoll};
