import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";
import { GetUser } from "../GetUser";

const token = DecryptToken();

async function getOrders(next){

  const url = next ? next : `${process.env.REACT_APP_API_URL}/order/`;
  const api = new Promise((resolve, reject) => {
      try{
          axios({
              method: 'get',
              url: url,
              headers: {
                  Authorization: token
              }
          }).then((response) => {
              resolve(response);
          }).catch((error) => {
              reject(error)
          });
      }
      catch (error){
          console.error(error);
      };
});

  try {
      const response = await api; // Wait for the Promise to be resolved.
      return response
    } catch (error) {
      return {
          error: error.message
      }; // Handle the error appropriately or return a default value.
    }
};


const getOrder = async (order_id) => {
  const api = new Promise((resolve, reject) => {
    try {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/order/${order_id}/`,
        headers: {
          Authorization: token
        }
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    }
    catch (error) {
      // console.log(error);
    };
  });

  try {
    const response = await api;
    return response;
  }
  catch (error){
    return error;
  };
};

// create an async promise function that delete order
const removeOrder = async (data) =>{

    const api = new Promise((resolve, reject) => {
    try{
        axios({
          method: 'delete',
          url: `${process.env.REACT_APP_API_URL}/order/${data}/`,
          headers: {
            Authorization: token
          },
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error)
        });
      }
      catch (error){
        // console.error(error);
      };
    });
  
    try {
      const response = await api; // Wait for the Promise to be resolved.
      return response
    }
    catch (error) {
      return {
          error: error
      }; // Handle the error appropriately or return a default value.
    };
  };

const removeRequestOrder = async (data, user_id, party_no) =>{
  /**
   * This function change order status to null 
   * and it send to waiting list to admin
   */
  const api = new Promise((resolve, reject) => {
    try{
      axios({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/order/${data}/`,
        headers: {
          Authorization: token
        },
        data: {
          "status": false,
          "is_delete_approved": false,
          "is_delete_rejected": false,
          "is_finish": false
          // "party_no": party_no
        }
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    }
    catch (error){
      // console.error(error);
    };
  });

  try {
    const response = await api; // Wait for the Promise to be resolved.
    return response
  }
  catch (error) {
    return error; 
  };
};

// create an async promise function that update order
const updateOrder = async (data, order_id) =>{
    const api = new Promise((resolve, reject) => {
        try{
            axios({
                method: 'patch',
                url: `${process.env.REACT_APP_API_URL}/order/${order_id}/`,
                headers: {
                    Authorization: token
                },
                data: {
                    "party_no": data,
                }
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error)
            });
        }
        catch (error){
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
    } 
    catch (error) {
        return error // Handle the error appropriately or return a default value.
    };
};

// create an async promise function that create new order
const createOrder = async (data) =>{
    const user =  await GetUser();
    const api = new Promise((resolve, reject) => {
      try{
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/order/`,
          headers: {
            Authorization: token
          },
          data: {
            "user": user.id,
            "party_no": data,
          }
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error)
        });
      }
      catch (error){
        console.error(error);
      };
    });
    
    try {
      const response = await api; // Wait for the Promise to be resolved.
      return response
    } catch (error) {
      return error // Handle the error appropriately or return a default value.
    }
  };


const GetOrderReport = async (id) => {
  const api = new Promise((resolve, reject) => {
    try{
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/report/order/${id}/`,
        headers: {
          Authorization: token
        },
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    }
    catch (error){
      console.error(error);
    };
  });

  try {
    const response = await api; // Wait for the Promise to be resolved.
    return response
  } catch (error) {
    return error // Handle the error appropriately or return a default value.
  }
};

const GetPendingOrders = async () => {
  /**
   * This function return pending orders
  */
  const api = new Promise((resolve, reject) => {
    try{
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/order/pending/`,
        headers: {
          Authorization: token
        },
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    }
    catch (error){
      console.error(error);
    };
  });

  try {
    const response = await api;
    return response;
  }
  catch (error) {
    return error;
  }
};


const GetOrderFlaws = async (id) => {
  const api = new Promise((resolve, reject) => {
    try{
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/order/${id}/flaws/`,
        headers: {
          Authorization: token
        },
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    }
    catch (error){
      console.error(error);
    };
  });

  try {
    const response = await api; // Wait for the Promise to be resolved.
    return response
  } catch (error) {
    return error // Handle the error appropriately or return a default value.
  }
};

const FinishParty = async (order_id) => {
  const api = new Promise((resolve, reject) => {
    try{

      axios({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/order/${order_id}/`,
        headers: {
          Authorization: token
        },
        data: {
          "status": false,
          "is_finish": true,
          "is_rejected": false,
          "is_delete_approve": false
        }
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    }
    catch (error) {
      // console.log(error)
    }
  });

  try {
    const response = await api;
    return response;
  }
  catch (error) {
    return error;
  };
};


const RejectParty = async (order_id) => {
  const api = new Promise((resolve, reject) => {
    try{

      axios({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/order/${order_id}/`,
        headers: {
          Authorization: token
        },
        data: {
          "status": false,
          "is_finish": true,
          "is_delete_rejected": true,
          "is_delete_approve": false
        }
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    }
    catch (error) {
      // console.log(error)
    }
  });

  try {
    const response = await api;
    return response;
  }
  catch (error) {
    return error;
  };
};


const ActivateParty = async (order_id) => {
  const api = new Promise((resolve, reject) => {
    try{

      axios({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/order/${order_id}/`,
        headers: {
          Authorization: token
        },
        data: {
          "status": true,
          "is_finish": false,
          "is_delete_rejected": false,
          "is_delete_approve": false
        }
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    }
    catch (error) {
      // console.log(error)
    }
  });

  try {
    const response = await api;
    return response;
  }
  catch (error) {
    return error;
  };
};


const getActiveRollOfOrder = async (order_id) => {
  const api = new Promise((resolve, reject) => {
    try{
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/order/${order_id}/rolls/?status=true`,
        headers: {
          Authorization: token
        },
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    }
    catch (error){
      console.error(error);
    };
  });

  try {
    const response = await api; // Wait for the Promise to be resolved.
    return response
  } catch (error) {
    return error // Handle the error appropriately or return a default value.
  }
}


const searchParty = async (partyNo) => {
  const api = new Promise((resolve, reject) => {
    try{
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/search/order/${partyNo}/`,
        headers: {
          Authorization: token
        },
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    }
    catch (error){
      console.error(error);
    };
  });

  try {
    const response = await api; // Wait for the Promise to be resolved.
    return response
  } catch (error) {
    return error // Handle the error appropriately or return a default value.
  }
};

export {getOrders, getOrder, GetPendingOrders, removeOrder, 
        removeRequestOrder, createOrder, updateOrder, 
        GetOrderReport, GetOrderFlaws, FinishParty, RejectParty, ActivateParty,
        getActiveRollOfOrder, searchParty};