import React,{useEffect, useState, startTransition} from "react";
import { getOrders, removeOrder, removeRequestOrder, createOrder, updateOrder } from "../../api/order/Order";
import OrderTable from "./OrderTable";
import { DecryptUserInfo } from "../../api/helpers/decrypt";
import { GetUser } from "../../api/GetUser";

import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardBody, 
  CardHeader, 
  Button,
  Alert,
  Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, FormFeedback, FormText
} from "reactstrap";
import { useTranslation } from 'react-i18next';


const Order = () => {
    
    const [party, setParty] = useState(null);
    const [partyNo, setPartyNo] = useState(null);
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [orderID, setOrderID] = useState(false);
    const [showFeedBack, setFeedBack] = useState(false);
    const [error, setError] = useState([]);
    const [warning, setWarning] = useState([]);
    const [success, setSuccess] = useState([]); 
    const [visible, setVisible] = useState(false);
    const [warningVisible, setWarningVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [orders, setOrders] = useState([]);
    const [currentUser, setUser] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const [waitingMessage, setWaitingMessage] = useState(null);
    const { t } = useTranslation('order'); // Using the 'dashboard' namespace

    const onDismiss = () => {setVisible(!visible)};

    const onWarningDismiss = () => {setWarningVisible(!warningVisible)};

    const onErrorDismiss = () => {setErrorVisible(!errorVisible)};

    const fetchUser = async () => {
        /**
         * This function fetch user data
        */
        const response = await GetUser();
        setUser(response.data);
    };

    const fetchOrder = async (url) => {
        /**
         * This function fetch order data
         * get count, next, prev data
         * and set data to state
        */
        // const url = `${process.env.REACT_APP_API_URL}/order/?limit=5`
        const response = await getOrders(url);
        if (response.request.status === 200) {
            setOrders(response.data);
        }
    };

    const getRemoveOrder = async (id) => {
        /**
         * Decide which function will be called
         * according to user status
        */
        const user = DecryptUserInfo();
        if (user.status === 'admin' || user.status === 'superadmin'){
            const response = await removeOrder(id);
            return response;
        }
        else{
            if (partyNo !== null){
                const response = await removeRequestOrder(id, currentUser.id, partyNo);
                return response;
            };
        }
    };

    const fetchStatusCode = (statusCode) => {
        /**
         * This function check status code
         * and show alert according to status code
         */
        if (statusCode === 400){
            setError(t('alreadyExistsParty'));
            onErrorDismiss();
        }
        else if (statusCode === 401){
            setError(t('noAuth'));
            onErrorDismiss();
        }
        else if (statusCode === 403){
            setError(t('noPermission'));
            onErrorDismiss();
        }
        else if (statusCode === 404){
            setError(t('serverError'));
            onErrorDismiss();
        }
        else if (statusCode === 200){
            setSuccess(t('successMsg'));
            onDismiss();
        };
    };

    const toggle = (event) => {
        /**  
         * when toggle open first clear previous machine id 
         * and then get new machine id & set new
        */
        setOrderID(event);
        setModal(!modal);
    };

    const editToggle = (event, id) => {
        /**   
         * when toggle open first clear previous order id and 
         * then get new order id & set new
        */
        event.preventDefault();
        setOrderID(id);
        setEditModal(!editModal);
    };

    const removeToggle = (event, party_no) => {
        /** 
         * when toggle open first clear previous machine id 
         * and then get new machine id & set new
        */
        setOrderID(event);
        setPartyNo(party_no);
        setRemoveModal(!removeModal);
    };

    const handleSubmit = async (event) => {
        /**
         * This function create new order
         * When form submitted, this function will be called
         * and the data will be updated
         * finally modal will be closed
         * and order data will be fetched again
         */
        event.preventDefault();
        setIsClicked(true);
        setWaitingMessage({
            type: 'warning',
            message: t('pleaseWait')
        });
        startTransition(async () => {
            // get input data
            const inputData = event.target[0].value;
            setParty(null);
            setFeedBack(true);
            // check input data
            if (inputData !== ''){
                const response = await createOrder(inputData);
                try{
                    const statusCode = response.request.status;
                    if (statusCode === 400){
                        setError(t('error400Msg'));
                        onErrorDismiss();
                    }
                    else if (statusCode === 401){
                        setError(t('noAuth'));
                        onErrorDismiss();
                    }
                    else if (statusCode === 403){
                        setError(t('noPermission'));
                        onErrorDismiss();
                    }
                    else if (statusCode === 404){
                        setError(t('error400Msg'));
                        onErrorDismiss();
                    }
                    else if (statusCode === 201){
                        setSuccess(t('successMsg'));
                        onDismiss();
                    };

                }
                catch (err){
                    // console.log(err);
                }
                finally{
                    setModal(!modal);
                    setTimeout(() => {
                        fetchOrder();
                    }, 100);
                    setIsClicked(false);
                    setWaitingMessage(null);
                };
            };
        });
    };

    const handleEditSubmit = async (event) => {
        /**
         * When edit form submitted, this function will be called
         * and the data will be updated
         * finally modal will be closed
         * and order data will be fetched again
         */
        event.preventDefault();
        // get input data
        const inputData = event.target[0].value;
        setFeedBack(true);
        // check input data
        if (inputData !== ''){
            const response = await updateOrder(inputData, orderID);
            try{
                const statusCode = response.request.status;
                fetchStatusCode(statusCode);
            }
            catch (err){
                // console.log(err);
            }
            finally{
                setEditModal(!editModal);
                setTimeout(() => {
                    fetchOrder();
                }, 100);
            };
        };
    };

    const handleRemoveSubmit = async (e) => {
        /**
         * When remove form submitted, this function will be called
         * and the data will be updated
         * finally modal will be closed
         * and order data will be fetched again
        */ 
        e.preventDefault();
        setIsClicked(true);
        setRemoveModal(!removeModal);
        if (orderID !== false){
            // check user status and call function according to user status
            const response = await getRemoveOrder(orderID);
            try{
                const statusCode = response.status;
                if (response.status === 200){
                    setOrderID(false);
                    setWarning(t('removeRequestSuccessMsg'));
                    onWarningDismiss();
                    fetchOrder();
                }
                else if (response.status === 204){
                    fetchOrder();
                    setOrderID(false);
                    setWarning(t('removeSuccessMsg'));
                    onWarningDismiss();
                }
                else {
                    fetchStatusCode(statusCode);
                }
            }
            catch (err){
                // console.log(err);
            }
        }     
        setIsClicked(false);
    };

    useEffect(() => {
        /**
         * when page loaded, this function will be called
         * and the data will be fetched
         * finally data will be set
         * and count, next, previous will be set
         */
        fetchOrder();
        fetchUser();
    }, []);

        
    return(
        <div className="page-content">
            <Container fluid>
                <Alert color="danger" className="custom-alert" isOpen={errorVisible} toggle={onErrorDismiss} >
                    <span>{error}</span>
                </Alert>
                <Alert color={"success"} className="custom-alert" isOpen={visible} toggle={onDismiss}>
                    <span>
                        { 
                            success !== false ? success :
                            t('successMsg')
                        }
                    </span>
                </Alert>
                <Alert color={"warning"} className="custom-alert" isOpen={warningVisible} toggle={onWarningDismiss}>
                    <span>
                        { 
                            warning !== false ? warning :
                            t('successMsg')
                        }
                    </span>
                </Alert>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h4 className='text-uppercase mb-0' >{t('orderList')}</h4>
                                <Button className="addButton" onClick={toggle}>
                                    {t('addProduct')}
                                </Button>
                            </CardHeader>
                            <CardBody className="mt-0 mx-3">
                                <OrderTable
                                    editToggle={editToggle} 
                                    removeToggle={removeToggle}
                                    orderData={orders}
                                    fetchOrder={fetchOrder}
                                    t={t}
                                    />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                {/* new order add modal */}
            <Row>
                <Col>
                    <Modal isOpen={modal} toggle={toggle} centered={true}>
                        <ModalHeader toggle={toggle} >{t('addProduct')}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        {
                                        waitingMessage && (
                                            <p className="text-warning text-center fw-bold p-2 bg-soft-warning"  
                                                style={{border: '0.5px solid #f7b84b', borderRadius: '3px'}}>
                                                {waitingMessage.message}
                                            </p>
                                        )
                                        }

                                        <Label for="machine_nane">
                                            {t('partyNo')}
                                        </Label>

                                        <Input
                                            type="text"
                                            name="party_no"
                                            id="party_no"
                                            placeholder="Party no..." 
                                            style={{border: '1px solid #80808047', borderRadius: '3px', disabled: true}} 
                                            onChange={(e) => setParty(e.target.value)}
                                            invalid={(showFeedBack && party === '' )}
                                        />
                                        { showFeedBack && party==='' || party === null
                                            &&
                                            <FormFeedback invalid>
                                                {t('emptyPartyWarning')}
                                            </FormFeedback>
                                        }
                                        
                                    </FormGroup>
                                    {/* // disabled={party === '' ? true : false} /disabled={isClicked && party === '' || isClicked ? true : false} > */}
                                    <Button color="warning" type="submit" disabled={party === '' || party === null || isClicked}>
                                        {t('addProduct')}
                                    </Button>
                                </Form>
                            </ModalBody>
                    </Modal>
                </Col>
            </Row>

            {/* Remove order modal */}
            <Row>
                <Col>
                    <Modal isOpen={removeModal} toggle={removeToggle} centered={true}>
                        <ModalHeader toggle={removeToggle} >{t('removeParty')}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleRemoveSubmit}>
                                    <FormGroup>
                                        <FormText>
                                            {t('removePartyQuestion')}
                                        </FormText>
                                    </FormGroup>
                                    <Button color="danger" type="submit">
                                        {t('remove')}
                                    </Button>
                                </Form>
                            </ModalBody>
                    </Modal>
                </Col>
            </Row>

            {/* edit order  modal */}
            <Row>
                <Col>
                <Modal isOpen={editModal} toggle={editToggle} centered={true}>
                        <ModalHeader toggle={editToggle} >{t('updateProduct')}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleEditSubmit}>
                                    <FormGroup>
                                        
                                        <Label for="machine_nane">
                                            {t('partyNumber')}
                                        </Label>

                                        <Input
                                            type="number"
                                            name="party_no"
                                            id="party_no"
                                            placeholder="Party no..." 
                                            style={{border: '1px solid #80808047', borderRadius: '3px', disabled: true}} 
                                            onChange={(e) => setParty(e)}
                                            invalid={(showFeedBack && party === '' || party === null )}
                                        />
                                        { showFeedBack && party==='' 
                                            &&
                                            <FormFeedback invalid>
                                                {t('noEmptyPartyWarning')}
                                            </FormFeedback>
                                        }
                                        
                                    </FormGroup>
                                    <Button color="warning" type="submit" disabled={party === '' || party === null ? true : false} >
                                        {t('update')}
                                    </Button>
                                </Form>
                            </ModalBody>
                    </Modal>
                </Col>
            </Row>
            </Container>
        </div>
    )
}


export default Order;