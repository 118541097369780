import React, {useEffect, useState} from "react";
import 
{ Button, 
  Alert,
  Modal, 
  ModalBody, 
  ModalHeader, 
  Col, 
  Container, 
  Card,
  CardBody,
  CardHeader,
  Row,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Label,
  Input} from "reactstrap";
import { getMachines, getMachineService, EditMachineAPI, removeMachineAPI, createMachineAPI } from "../../api/machine/Machine";
import MachineDetailTable from "../../Layout/tables/MachineDetailTable";
import { DecryptUserInfo } from "../../api/helpers/decrypt";
import { useTranslation } from 'react-i18next';


const Machine = () => {

    const [modal, setModal] = useState(false);  
    const [removeModal, setRemoveModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [name, setName] = useState('')
    const [machineID, setMachineID] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [nameFeedback, setNameFeedback] = useState(false);
    const [existNames, setExistNames] = useState([]);
    const [machine, setMachine] = useState({});
    const [machineService, setMachineService ] = useState([]);
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(false);
    const [userStatus, setUserStatus] = useState(null);
    const [count, setCount] = useState(0);
    const { t } = useTranslation(['table']); // Using the 'dashboard' namespace



    const onDismiss = () => {setVisible(!visible)};

    const onErrorDismiss = () => {setErrorVisible(!errorVisible)};
    
    const fetchMachine = async (next=null) => {
        // fet all machines from backend
        const response = await getMachineService(next);
        const data = response.data;

        // assign data to machine state with prev data
        setMachineService(prevMachineService => [...prevMachineService, ...data.results]);
        if (data.next){
            fetchMachine(data.next);
        }
        else {
            // setIsReady(true);
        }
    }

    useEffect(() => {
        // get user status
        const user = DecryptUserInfo();
        setUserStatus(user.status);
        // fetch all machines from backend
        fetchMachine();
    }, []);


    const toggle = async (event) => {
        // when toggle open first clear previous machine id and then get new machine id & set new
        // const status = await GetUser();
        if ( DecryptUserInfo().status === 'superadmin'){
            setMachineID(false);
            if (event !== false){
                setMachineID(event);
            }
            else if (event === false) {
                setMachineID(event);    
            }
            setModal(!modal)
        } else {
            setErrorMsg('İşlemi gerçekleştirmek için yeterli yetkiye sahip değilsiniz!');
            setErrorVisible(!errorVisible);
        }
    }

    const removeToggle = async (event) => {
        // when toggle open first clear previous machine id and then get new machine id & set new
        if ( DecryptUserInfo().status === 'superadmin'){

            setMachineID(false);
            if (event !== false){
                setMachineID(event);
            }
            else if (event === false) {
                setMachineID(event);    
            }
            setRemoveModal(!removeModal);
        } else {
            setErrorMsg('İşlemi gerçekleştirmek için yeterli yetkiye sahip değilsiniz!');
            setErrorVisible(!errorVisible);
        }
    };

    const createToggle = async (event) => {
        if ( userStatus === 'superadmin'){
            setCreateModal(!createModal);
        } else {
            setErrorMsg('İşlemi gerçekleştirmek için yeterli yetkiye sahip değilsiniz!');
            setErrorVisible(!errorVisible);
        }
    };
    
    const nameCheck = (name) => {
        // check name condition is exist 
        const statu = existNames.includes(name);
        setNameFeedback(statu)
        // return statu because setNameFeedback not working immediatly 
        return statu;
    }
    
    const handleRemoveSubmit = async (e) => {
        e.preventDefault();
        setRemoveModal(!removeModal);
        if (machineID !== false){
            const response = await removeMachineAPI(machineID);
            try{
                // for catch error while user is deleting the machine
                const statusCode = response.error.response.status;

                if (statusCode === 400){
                    setErrorMsg("Kaldırma işlemi yapılırken bir sorunla karşılaştık!");
                    onErrorDismiss();
                }
                else if (statusCode === 401){
                    setErrorMsg('Kimliğinizi doğrulayamadık!');
                    onErrorDismiss();
                }
                else if (statusCode === 403){
                    setErrorMsg('İşlemi gerçekleştirmek için yeterli yetkiye sahip değilsiniz!');
                    onErrorDismiss();
                }
                else if (statusCode === 404){
                    setErrorMsg('Seçili makina bulunamadı!');
                    onErrorDismiss();
                }
            }
            catch (err){
                //
            }
            finally{
                setMachineID(false);
            }

            try{
                // for the succes remove
                if (response.status === 204){
                    setSuccessMsg('Seçili makina başarılı bir şekilde kaldırıldı');
                    setTimeout(() => {
                        setMachineService([]);
                        fetchMachine();
                    }, 100)
                    onDismiss();
                }
            }
            catch (err){
                //
            }
            finally{
                //
                setMachineID(false);
            }
            
        }
        else{
            // if there is a problem about machine id 
            // show error alert box
            setErrorMsg('Seçili makina bulunamadı!');
            setErrorVisible(!errorVisible);
        }
        
    };

    const handleCreateSubmit = async (e) => {
        e.preventDefault();
        const input = e.target[0].value;
        // check name is exist or not
        const nameCondition = nameCheck(input);
        setShowFeedback(true);

        // check the condition
        // if name is not null and name is not exist close the modal
        if (name !== '' & nameCondition===false){
            // update machine
            const response = await createMachineAPI(input);

            try {
                // for catch error 
                const statusCode = response.error.response.status;

                if (statusCode === 400){
                    setErrorMsg('Girilen makina ismi daha önce kullanılmış!');
                    onErrorDismiss();
                }
                else if (statusCode === 403){
                    setErrorMsg('İşlemi gerçekleştirmek için yeterli yetkiye sahip değilsiniz!');
                    onErrorDismiss();
                }
                else if (statusCode === 404){
                    setErrorMsg('Seçili makina bulunamadı!');
                    onErrorDismiss();
                }
                else if (statusCode === 401){
                    setErrorMsg('Kimlik doğrulamanız başarısız!');
                    onErrorDismiss();
                }
            }
            catch (error){
                
            }
            finally {
                // and clear the name and close the modal in the final
                setName('');
                setShowFeedback(false);
                createToggle(false);
            }

            try{
                // for success result
                if (response.status === 201){
                    // we should wait a little because of changed have to be applied
                    setTimeout(() => {
                        setMachineService([]);
                        fetchMachine();
                    }, 100)
                    setSuccessMsg('Makina başarılı şekilde oluşturuldu');
                    onDismiss();
                }
            }
            finally{
                setName('');
                setShowFeedback(false);
                createToggle(false);
            }   
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const input = e.target[0].value;
        // check name is exist or not
        const nameCondition = nameCheck(input);
        setShowFeedback(true);
        // check the condition
        // if name is not null and name is not exist close the modal
        if (name !== '' & nameCondition===false){
            // update machine
            const response = await EditMachineAPI(machineID, input);

            try {
                // for catch error 
                const statusCode = response.error.response.status;

                if (statusCode === 400){
                    setErrorMsg('Girilen makina ismi daha önce kullanılmış!');
                    onErrorDismiss();
                }
                else if (statusCode === 403){
                    setErrorMsg('İşlemi gerçekleştirmek için yeterli yetkiye sahip değilsiniz!');
                    onErrorDismiss();
                }
                else if (statusCode === 404){
                    setErrorMsg('Seçili makina bulunamadı!');
                    onErrorDismiss();
                }
                else if (statusCode === 401){
                    setErrorMsg('Kimlik doğrulamanız başarısız!');
                    onErrorDismiss();
                }
            }
            catch (error){
                
            }
            finally {
                // and clear the name and close the modal in the final
                setName('');
                setShowFeedback(false);
                toggle(false);
            }

            try{
                // for success result
                if (response.status === 200){
                    // we should wait a little because of changed have to be applied
                    setTimeout(() => {
                        // dispatch(initializeMachineService());
                        setMachineService([]);
                        fetchMachine();
                    }, 100)
                    onDismiss();
                }
            }
            finally{
                setName('');
                setShowFeedback(false);
                toggle(false);
            }   
        };
    };


    return(
        <div className="page-content">
            <Container fluid>
                <Alert color="danger" className="custom-alert" isOpen={errorVisible} toggle={onErrorDismiss} >
                    <span>{errorMsg}</span>
                </Alert>
                <Alert color={"success"} className="custom-alert" isOpen={visible} toggle={onDismiss}>
                    <span>
                        { 
                            successMsg !== false ? successMsg :
                            t('successMsg')
                        }
                    </span>
                </Alert>
                
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="d-flex justify-content-between">
                                <h4 className='text-uppercase mb-0' >{t('machineList')}</h4>
                                { userStatus === 'superadmin' &&
                                    <Button className="addButton" onClick={createToggle}>
                                        {t('addMachine')}
                                    </Button>
                                }
                            </CardHeader>
                            <CardBody>
                                <MachineDetailTable 
                                    toggle={toggle} 
                                    removeToggle={removeToggle} 
                                    machine={machineService} 
                                    userStatus={userStatus}
                                    t={t}
                                    />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* Edit machine modal */}
                        <Modal isOpen={modal} toggle={toggle} centered={true}>
                            <ModalHeader toggle={toggle} >{t('machineDetails')}</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            
                                            <Label for="machine_nane">
                                                {t('machineName')}
                                            </Label>
                                            <Input
                                                type="text"
                                                name="machine_name"
                                                id="machine_name"
                                                placeholder="Example Machine Name..." 
                                                style={{border: '1px solid #80808047', borderRadius: '3px'}} 
                                                onChange={(e) => setName(e)}
                                                invalid={(showFeedback && name === '') || ( showFeedback && nameFeedback) }    
                                            />
                                            { showFeedback && name==='' 
                                                &&
                                                <FormFeedback invalid>
                                                    {t('emptyMachineMsg')}
                                                </FormFeedback>
                                            }

                                            {   showFeedback && nameFeedback
                                                && 
                                                <FormFeedback invalid>
                                                    {t('alreadyExistMachineMsg')}
                                                </FormFeedback>

                                            }
                                            
                                        </FormGroup>
                                        <Button color="primary" type="submit">
                                            {t('save')}
                                        </Button>
                                    </Form>
                                </ModalBody>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* Remove machine modal */}
                        <Modal isOpen={removeModal} toggle={removeToggle} centered={true}>
                            <ModalHeader toggle={removeToggle} >{t('removeMachine')}</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={handleRemoveSubmit}>
                                        <FormGroup>
                                            <FormText>
                                                {t('machineDeleteMsg')}
                                            </FormText>
                                        </FormGroup>
                                        <Button color="danger" type="submit">
                                            {t('remove')}
                                        </Button>
                                    </Form>
                                </ModalBody>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* Create machine modal */}
                        <Modal isOpen={createModal} toggle={createToggle} centered={true}>
                            <ModalHeader toggle={createToggle} >{t('machineCreate')}</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={handleCreateSubmit}>
                                        <FormGroup>
                                            
                                            <Label for="machine_nane">
                                                {t('machineName')}
                                            </Label>
                                            <Input
                                                type="text"
                                                name="machine_name"
                                                id="machine_name"
                                                placeholder="Enter machine name"
                                                style={{border: '1px solid #80808047', borderRadius: '3px'}} 
                                                onChange={(e) => setName(e)}
                                                invalid={(showFeedback && name === '') || ( showFeedback && nameFeedback) }    
                                            />
                                            { showFeedback && name==='' 
                                                &&
                                                <FormFeedback invalid>
                                                    {t('emptyMachineMsg')}
                                                </FormFeedback>
                                            }

                                            {   showFeedback && nameFeedback
                                                && 
                                                <FormFeedback invalid>
                                                    {t('alreadyExistMachineMsg')}
                                                </FormFeedback>

                                            }
                                            
                                            
                                        </FormGroup>
                                        <Button color="success" type="submit">
                                            {t('create')}
                                        </Button>
                                    </Form>
                                </ModalBody>
                        </Modal>
                    </Col>
                </Row>


            </Container>
        </div>

    )

}
export default Machine;