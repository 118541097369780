import React, { startTransition } from 'react';
import { useTranslation } from 'react-i18next';
import OrderDeleteMessage from "./Message";
import {Button} from "reactstrap";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        startTransition(() => {
            i18n.changeLanguage(lng);
        });
    };

    return (
        <div style={{ padding: '1rem' , position: 'absolute', top: '0.5rem', left: '1rem', width: '100%' }}>
            <Button size="sm" onClick={() => changeLanguage('en')} className="me-2" color="outline-secondary">English</Button>
            <Button size="sm" onClick={() => changeLanguage('tr')} color="outline-danger">Türkçe</Button>
        </div>
    );
}

export default LanguageSwitcher;
