/*
    * Basic helper functions 
*/

const convertMonth = (month) => {
    /*  convert month number to month name */
    switch (month) {
        case 1:
            return 'Ocak'
        case 2:
            return 'Şubat'
        case 3:
            return 'Mart'
        case 4:
            return 'Nisan'
        case 5:
            return 'Mayıs'
        case 6:
            return 'Haziran'
        case 7:
            return 'Temmuz'
        case 8:
            return 'Ağustos'
        case 9:
            return 'Eylül'
        case 10:
            return 'Ekim'
        case 11:
            return 'Kasım'
        case 12:
            return 'Aralık'
        default:
            return ''
    }
};

const getTime = (date) => {
    /*
    * get full date and return hour and minute  hh:mm
    * check minute is smaller than 10
    * if it small add 0 start of minute
    */
   const time = new Date(date).toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })
   return time
}


const convertDate = (date) => {
    /*
     * I use this function to convert date to Turkish date format
     * example: 2021-09-21T14:00:00Z -> 21 Eylül 2021
     */
    if (date) {
        const newDate = new Date(date);
        return `${newDate.getDate()} ${convertMonth(newDate.getMonth() + 1)} ${newDate.getFullYear()} ${getTime(newDate)}`
    }
}

export { convertMonth, convertDate };
