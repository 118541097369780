import React, {useState, useEffect} from "react";
import { Card, CardBody, Input, Col, Row, Form, Button, UncontrolledAlert, Container } from "reactstrap";
import { CreateUser } from "../../api/auth/CreateUser";

import { RotatingLines } from  'react-loader-spinner'


const CreateUserComponent = (props) => {
    const {loadDatas} = props;
    const [visible, setVisible] = useState(false);
    const [msg, setMsg] = useState([]); // [msg, isSuccess] -> error : [msg, false], success : [msg, true]

    const toggle = () => setVisible(!visible);
    const [loader, setLoader] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        const firstName = document.getElementById("first_name");
        const lastName = document.getElementById("last_name");
        const usernameInput = document.getElementById("username");
        const passwordInput = document.getElementById("password");


        if (usernameInput.value === "" || passwordInput.value === "") {
            setMsg([props.t('emptyUserFieldMsg'), false]);
            setVisible(true);
            setLoader(false);
            setTimeout(() => {
                setVisible(false);
            }, 10000);
        }
        else if (passwordInput.value.length < 8) {
            setMsg([props.t('weekPassword'), false]);
            setVisible(true);
            setLoader(false);
            setTimeout(() => {
                setVisible(false);
            }, 10000);
        }
        else {
            /* eğer hiçbir hata yoksa kullanıcıyı oluşturma fonksiyonuna yönlendir */
            const data = {
                "username": usernameInput.value,
                "password": passwordInput.value,
                "password2": passwordInput.value,
                "email": "",
                "first_name": firstName.value,
                "last_name": lastName.value,
                "is_verified": true,
            }

            CreateUser(data).then((response) => {
                try {
                    const status = response.request.status;
                    if (status === 201) {
                        // show success message
                        setMsg([props.t('createdUserMsg'), true]);
                        setVisible(true);
                        setLoader(false);
                        setTimeout(() => {
                            setVisible(false);
                        }, 10000);
                        // clear inputs
                        firstName.value = null;
                        lastName.value = null;
                        usernameInput.value = "";
                        passwordInput.value = "";
                        // reload datas
                        loadDatas();
                        
                    }
                    else if (status === 400) {
                        const error = response.response.data;
                        const errorKeys = Object.keys(error);
                        const errorMsg = errorKeys.map((key) => (
                            key === "password" ? props.t('weekPassword2') : props.t('existsUser')
                        ));
                        // show error message
                        setMsg([errorMsg, false]);
                        setVisible(true);
                        setLoader(false);
                        setTimeout(() => {
                            setVisible(false);
                        }, 10000);
                    }
                    else {
                        // error
                        setMsg([props.t('serverError'), false]);
                        setVisible(true);
                        setLoader(false);
                        setTimeout(() => {
                            setVisible(false);
                        }, 10000);
                    }
                }
                catch (e) {
                    // console.log(e);
                };
            });
        }

        
        

        
    }


    return ( 
        <Container fluid>
            <div style={{position:'relative'}}>
                <div className="loader" 
                    style={{background: '#a5a5a5b5', position: 'absolute', 
                            top: '0', right:'0', left: '0', bottom: '0', 
                            zIndex: '1000000', display: loader ? 'block': 'none',
                            overflow: 'hidden'}}>
                    <div className="loader-animation" style={{position: 'relative', top: '40%', left: '40%', color:'#00425a'}}>
                        <RotatingLines
                            strokeColor="#00425a"
                            strokeWidth="3"
                            animationDuration="1"
                            width="48"
                            visible={true}
                            wrapperStyle={{}}
                        />
                    </div>
                    <p style={{position: 'relative', top: '40%', left: '40%', translate: '-25px', transform: 'translateY(10px)', color:'#00425a'}}>
                        {props.t('loading')}
                    </p>
                </div>

                <UncontrolledAlert color={msg && msg[1] ? 'success' : 'danger'} isOpen={visible} toggle={toggle} fade={true} className="text-center" >
                    {msg && <strong>{msg[0]}</strong>}
                </UncontrolledAlert>

                <Card>
                    <CardBody>
                        <h1 className="card-title text-center text-uppercase">{props.t('newUserTitle')}</h1>
                        {/* add alert icon and add small info text */}
                        <p className="aling-items-center mb-2">
                            <i class='bx bx-error-circle text-danger me-2 fs-12'></i>
                            <small className="text-muted">{props.t('newUserWarning')}</small>
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12} md={12} lg={6} xl={6}>
                                    <Input 
                                        type="text" 
                                        name="first_name" 
                                        id="first_name" 
                                        placeholder={props.t('newUserFirstName')}
                                        style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }} />
                                </Col>
                                <Col sm={12} md={12} lg={6} xl={6}>
                                    <Input 
                                        type="text" 
                                        name="last_name" 
                                        id="last_name" 
                                        placeholder={props.t('newUserLastName')}
                                        style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }} />
                                </Col>
                            </Row>
                            <Input 
                                type="text" 
                                name="username" 
                                id="username" 
                                placeholder={props.t('newUserUsername')}
                                style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }} />
                            <Input 
                                type="password" 
                                name="password" 
                                id="password" 
                                placeholder={props.t('newUserPassword')}
                                style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }} />
                            <Button type="submit" className="w-100 btn-dark">{props.t('create')}</Button>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </Container>
    );
};

export default CreateUserComponent;