import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader, Col, Button, Container, Row } from "reactstrap";
import { GetOrderReport } from "../../api/order/Order";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const OrderGeneral = (props) => {
    const {flaw, rollCount} = props;
    const {id} = useParams();
    const [orderRollCount, setOrderRollCount] = useState(0);
    const [orderMillimeter, setOrderMillimeter] = useState(0);
    const [flawData, setFlawData] = useState([]);
    const { t } = useTranslation();
    
    

    useEffect(() => {
        // get order's roll datas and roll count from api
        const fetchOrderData = () => {
            const orderData = GetOrderReport(id);
            orderData.then((response) => {
                // setOrderCount(response.data.order_report.roll_count);
                setOrderMillimeter(response.data.order_report.millimeter);
            })
            .catch((error) => {
                // console.log(error);
            });
        }

        const interval = setInterval(fetchOrderData, 2000);
        return () => clearInterval(interval);

    }, []);

    useEffect(() => {
        setOrderRollCount(rollCount);
    }, [rollCount]);


    useEffect(() => {
        setFlawData(flaw);
    }, [flaw]);


    return (
        <Container fluid>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <Row className="text-center justify-content-evenly " >
                                <Col xs={12} sm={4} md={4} lg={3}
                                    style={{backgroundColor: 'rgba(106, 218, 125, 0.18)', padding: '10px', borderRadius: '5px'}}>
                                    <Row className="align-items-center justify-content-center">
                                        <Col xs={12} sm={12} md={0} lg={3} >
                                            <i className='bx bx-tachometer fs-2 text-success bg-soft-success md-none'
                                               style={{padding:'12px', borderRadius: '100%'}}></i>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                            <p className="">
                                                <strong className="fs-2">{orderMillimeter && orderMillimeter / 1000 }</strong>
                                                <p className="text-muted">{props.t('length')}</p>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={3}
                                    style={{backgroundColor: 'rgba(247, 184, 75, 0.18)', padding: '10px', borderRadius: '5px'}}>
                                    <Row className="align-items-center justify-content-center">
                                        <Col xs={12} sm={12} md={12} lg={3}>
                                            <i className='bx bx-plus-circle fs-2 text-warning bg-soft-warning md-none' 
                                               style={{padding:'12px', borderRadius: '100%'}}></i>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                            <p className="">
                                                <strong className="fs-2">{orderRollCount && orderRollCount}</strong>
                                                <p className="text-muted">{props.t('rollCount')}</p>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={3}
                                    style={{backgroundColor: 'rgba(250, 137, 107, 0.18)', padding: '10px', borderRadius: '5px'}}>
                                    <Row className="align-items-center justify-content-center">
                                        <Col xs={12} sm={12} md={12} lg={3}>
                                            <i class='bx bx-error-alt fs-2 text-danger bg-soft-danger md-none' 
                                               style={{padding:'12px', borderRadius: '100%'}}></i>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                            <p className="">
                                                {
                                                    flawData && <strong className="fs-2">{flawData.count}</strong>
                                                }
                                                <p className="text-muted">{props.t('flawCount')}</p>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default OrderGeneral;