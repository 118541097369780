import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();

const ExcelReport = async (filters) => {
    // filter flaws according to given filters
    function jsonToQueryString(json) {
        return Object.keys(json)
            .map(key => json[key] && `${key}=${json[key]}`)
            .join('&');
    }

    const params = filters && jsonToQueryString(filters);

    const url = `${process.env.REACT_APP_API_URL}/report/?${params}`

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url:  url,
            headers: {
                'Authorization': token
            },
            responseType: 'blob',
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

export default ExcelReport;
