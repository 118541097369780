import React, {useState, useEffect} from "react";
import { Button, Card, CardBody, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { GetRollMetric } from "../../api/RollMetric/RollMetric";
import { GetRollFlaw } from "../../api/roll/Roll";
import { DecryptToken } from "../../api/helpers/decrypt";
import { isEmptyArray } from "formik";


const FlawMap = (props) => {
    const {flaws, flawWColor, currentRoll, getClearImage} = props;
    const [flaw, setFlaw] = useState(null);
    const [height, setHeight] = useState(0);  // flaw map height
    const [width, setwidth] = useState(0);  // flaw map width

    const [modal, setModal] = useState(false);
    const [flawDetail, setFlawDetail] = useState({});

    const toggle = (data) => { 
        try{
            setFlawDetail(data);
        }
        catch(e){
            // console.log(e);
        }
        finally{
            setModal(!modal);
        };
    }

    const callRollMetric = async () => {
        // call the roll metric api
        // if the roll metric height is bigger than the flaw map height, update the flaw map height
        if (!isEmptyArray(flaw)){
            const response = await GetRollMetric(currentRoll.id);
            const yCoordinates = flaw.map(item => parseInt(item.flaw_coordinate.y));  // get all y coordinates of the flaws
            const xCoordinates = flaw.map(item => parseInt(item.flaw_coordinate.x));  // get all x coordinate of flaw
            const maxYCoordinate = Math.max(...yCoordinates);  // get the max y coordinate of the flaws
            const maxXCoordinate = Math.max(...xCoordinates);  // get the max x coordinate of the flaws
            const height = parseInt(response.data.height);  // get the roll metric height
            const width = parseInt(response.data.width)  // get roll metric width  
            if (height < maxYCoordinate) {
                setHeight(maxYCoordinate * 0.01 + maxYCoordinate);
            }
            else {
                setHeight(height); 
            }

            if (width < maxXCoordinate) {
                setwidth(maxXCoordinate * 0.08 + maxXCoordinate);
            }
            else {
                setwidth(width);
            }
        };
    };

    useEffect(() => {
        // callRollFlaw();
        // update height of the flaw map according to the roll metric height
        if (currentRoll != null){
            setTimeout(() => {
                callRollMetric();
            }, 1000);
        }
    }, [flaw]);


    useEffect(() => {
        try {
            // check the flaws data coming from the api
            // if it is not empty, set the flaw state
            if (!isEmptyArray(flaws)){
                setFlaw(flaws.results);
            }
        }
        catch  (e) {
            console.log(e);
        }

    }, [flaws]);

    const handleDownload = () => {
        if (modal && flawDetail && flawDetail.image) {
          const imageUrl = `${process.env.REACT_APP_API_URL}${flawDetail.image}`;
          fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `party_${flawDetail.order}_roll_${flawDetail.roll}_flaw_${flawDetail.flaw_count_of_roll}.jpeg`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(error => {
              console.error('Error downloading image:', error);
            });
        }
      };


    return (
        <Container fluid>
            {/* MODALS */}
            {/* create a modal for show the details of the flaw */}
            <Modal isOpen={modal} 
                   toggle={toggle} 
                   centered={true} 
                   size={"xl"}>
                <ModalHeader toggle={toggle} className="px-5 ms-3 text-uppercase">
                    <h4>{props.t('flawDetails')}</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent:'center', alignItems: 'center'}} >
                            { flawDetail && flawDetail.flaw_coordinate &&
                                <div >
                                    <img id={`flaw_image_${flawDetail.id}`}  
                                        src={modal && `${process.env.REACT_APP_API_URL}${getClearImage(flawDetail.image)}`} alt="flaw_image"
                                        loading="lazy"
                                        style={{
                                                width: '100%', 
                                                maxWidth: '100%',
                                                borderRadius: '3px'
                                        }}
                                    />
                                </div>
                            }
                            
                        </div>
                        <div className="col-md-6 text-center mt-4 fs-5">
                                <div className="row pt-3">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{props.t('flawType')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold fs-5'>{modal && flawWColor[flawDetail.flaw_type_code].name }</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{props.t('flawWidth')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail.flaw_size.width} <span className='text-muted'>{props.t('millimeter')}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{props.t('flawHeight')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail.flaw_size.height} <span className='text-muted'>{props.t('millimeter')}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{props.t('flawPlace')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail.millimeter/1000} <span className='text-muted'>{props.t('meter')}</span></p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </ModalBody>
            </Modal>
            {/* END OF MODALS */}

            {/* <Card style={{minHeight:'500px', maxHeight:'500px'}}> */}
                <div className="p-0  overflow-auto d-flex justify-content-center" style={{minHeight:'500px', maxHeight: '500px' }}>
                    <svg id="canvas" height='5000px' width={'500px'} style={{backgroundColor:'#9d6d222e', width:'500px'}} >
                        { flaw && flaw.map((item) => {
                            const flaw_type = item.flaw_type_code;
                            const x = item.flaw_coordinate.x;
                            const y = item.flaw_coordinate.y;
                            // const xRatio = 500 / 14400;
                            const xRatio = 500 / width;
                            let yRatio = 5000 / height;

                            return (
                                <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" 
                                    key={item.id}
                                    x = {x * xRatio}
                                    y = {y * yRatio}
                                    width={20}
                                    height={20}
                                    fill={flawWColor[flaw_type] ? flawWColor[flaw_type].color : 'orange'}
                                    stroke="black"
                                    cursor={"pointer"}
                                    onClick={() => toggle(item)}
                                    viewBox="0 0 52 52" 
                                    enableBackground="new 0 0 52 52" 
                                    >
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier"> 
                                            <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z"></path> 
                                        </g>
                                </svg>
                                    
                            );
                            })}
                    </svg>
                </div>
            {/* </Card> */}
        </Container>
    )
}

export default FlawMap;