import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { searchParty } from '../../api/order/Order';
import { FlawTypeDropdown } from '../../api/flaw/Flaw';
import Select from 'react-select';

const FlawTypeFilter = (props) => {
    // Filter flaw types 
    const [options, setOptions] = useState([]);
    const [defaultValue, setDefaultValue] = useState();

    const fetchOptions = async () => {
        // Fetch Flaw Types from API
        const response = await FlawTypeDropdown();
        const data = response.result.map((item) => ({
            value: item[0],
            label: item[1]
        }));
        setOptions(data);
    }

    useEffect(() => {
      fetchOptions();
    }, []);

    useEffect(() => {
        if (props.defaultValue) {
            setDefaultValue(props.defaultValue);
        }
    }, [props.defaultValue]);
    
    return (
        <Select isMulti options={options}
                placeholder={props.t('flawTypePlaceHolder')}
                noOptionsMessage={() => {props.t('noResult')}}
                loadingMessage={() => {props.t('loading')}}
                defaultValue={defaultValue && defaultValue}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? 'grey' : 'dodgerblue',
                  }),
                  menu: base => ({
                    ...base,
                    background: 'white',
                    zIndex: 2
                  })
                }}
                onChange={(e) => props.setFlawType(e)}
                />
    )
}


const PartyFilter = (props) => {
    // Filter party numbers
    const handleSearch = async (partyNo) => {
        // Search Party Number from API
        const response = await searchParty(partyNo);
        const data = response.data;
        return data;
    }
    
    const promiseOptions = (inputValue) =>
        new Promise ((resolve) => {
          setTimeout(() => {
            resolve(handleSearch(inputValue));
          }, 100);
        });


    return (
        <AsyncSelect cacheOptions defaultOptions 
                     isMulti loadOptions={promiseOptions}
                     placeholder={props.t('partyNoPlaceHolder')}
                     noOptionsMessage={() => {props.t('noResult')}}
                     loadingMessage={() => {props.t('loading')}}
                     styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'grey' : 'dodgerblue',
                      }),
                      menu: base => ({
                        ...base,
                        background: 'white',
                        zIndex: 2
                      })
                    }}
                    onChange={props.setPartyNo}
                     />
    )
};

export {PartyFilter, FlawTypeFilter};