import React, { useEffect, useMemo, useState } from 'react';
import {Button, Input} from "reactstrap";
import {convertDate} from "../../Components/Common/helper";

const OrderTable = (props) => {
    const {editToggle, removeToggle, orderData, fetchOrder} = props;
    const [data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(null);

    useEffect(() => {
        if (search) {
            const param = {party_no: search}
            const url = `${process.env.REACT_APP_API_URL}/order/?party_no=${search}`;
            fetchOrder(url);
            setCurrentPage(1);
        }
        else if (search === '') {
            fetchOrder();
        }

    }, [search]);

    useEffect(() => {
        /**
        * if any of the data changes, this function will be called
        * and the data will be updated
        */
        setData(orderData);
    }, [orderData]);

    const changePage = async (e, rotation) => {
        /**
         * I use this function to change page
         * I do not call all the data with recursive function like in the machine table
         * because the data may be too much and it takes too long to load
         */
        const url = data[rotation];  // get data according to rotation
        fetchOrder(url);
    };


    const searchTable = data && data.results && data.results.map((item) => {
        const data = {
            "id": item.id,
            "user": item.user.username,
            "party_no": item.party_no,
            "status": item.status,
            "is_delete_approve": item.is_delete_approve,
            "is_delete_rejected": item.is_delete_rejected,
            "is_finish": item.is_finish,
            "created_at": new Date(item.created_at),
            "updated_at": new Date(item.updated_at),
            "started_at": item.started_at != null ? convertDate(item.started_at) : null,
            "ended_at": item.ended_at != null ? convertDate(item.ended_at) : null,
        }
        return data
    });

    const columns = useMemo(
        () => [
            // <-- Deleted the id column from here -->
            {
                Header: props.t('partyNo'),
                accessor: "party_no",

            },
            {
                Header: "Operator",
                accessor: "user",
                Cell: ({ cell: { value }, row: { original } }) => (
                    <span className="fw-semibold text-uppercase">{value}</span>
                )
            },
            {
                Header: props.t('status'),
                accessor: "status",
                Cell: ({ cell: { value }, row: { original } }) => {
                    if (original.status === false &&
                        original.is_delete_approve === false &&
                        original.is_delete_rejected === false &&
                        original.is_finish === false) {
                        return (
                            <span className="badge bg-soft-warning text-warning">
                                    {props.t('waitingDeleteMsg')}
                                </span>
                        )
                    } else if (
                        original.status === false &&
                        original.is_delete_approve === false &&
                        original.is_delete_rejected === true &&
                        original.is_finish === true) {
                        return (
                            <span className="badge bg-soft-danger text-danger">
                                    {props.t('finish')}
                                </span>
                        )
                    } else if (
                        original.status === true &&
                        original.is_finish === false) {
                        return (
                            <span className="badge bg-soft-success text-success">{props.t('active')}</span>
                        )
                    } else if (
                        original.status === false &&
                        original.is_finish === true) {
                        return (
                            <span className="badge bg-soft-danger text-danger">{props.t('finish')}</span>
                        )
                    }
                }
            },
            {
                Header: props.t('startDate'),
                accessor: "started_at",
                Cell: ({ cell: { value }, row: { original } }) => {
                    if (value === null) {
                        return (
                            <span className="badge bg-soft-warning text-warning">{props.t('notStart')}</span>
                        )
                    } else {
                        return (
                            <span className="fw-semibold">{value}</span>
                        )
                    }
                }
            },
            {
                Header: props.t('finishDate'),
                accessor: "ended_at",
                Cell: ({ cell: { value }, row: { original } }) => {
                    if (value === null) {
                        return (
                            <span className="badge bg-soft-warning text-warning">{props.t('notFinish')}</span>
                        )
                    } else {
                        return (
                            <span className="fw-semibold">{value}</span>
                        )
                    }
                },
            },
            {
                Header: props.t('processes'),
                Cell: ({ cell: { value }, row: { original } }) => {
                    return (
                        <div>
                            <a href={`/order/${original.id}`} >
                                <i className="bx bx-show-alt bg-soft-primary text-primary fs-6 me-3 p-1"
                                    style={{cursor: 'pointer', border: '1px solid', borderRadius: '5px'}}>
                                </i>
                            </a>
                            <i className="bx bxs-edit bg-soft-success text-success fs-6 me-3 p-1"
                                style={{cursor: 'pointer', border: '1px solid', borderRadius: '5px'}}
                                onClick={
                                    (e) => editToggle(e, original.id)
                                }
                            ></i>
                            <i className="bx bx-trash bg-soft-danger text-danger fs-6 p-1"
                                style={{cursor: 'pointer', border: '1px solid', borderRadius: '5px'}}
                                onClick={() => removeToggle(original.id, original.party_no)}
                            ></i>
                        </div>
                    )
                }
            }
            ],
        [props.t]
    );

    const handlePage = () => {
        // handle page count
        const page = Math.ceil(data && data.count / 5);
        setPage(page);
    }

    const handlePageChange = async (e, rotation) => {
        await changePage(e, rotation)
        if (rotation === 'next')
            setCurrentPage(currentPage + 1);
        else
            setCurrentPage(currentPage - 1);
    }

    useEffect(() => {
        handlePage();
    }, [searchTable])


    return (
        <div>
            <div className="g-3 row">
                <div className="col">
                    <div className="search-box mb-2 d-inline-block col-12"
                        style={{border: "1px solid rgba(128, 128, 128, 0.28)", borderRadius: "3px"}}>
                        <Input id="search-bar-0" type="text"
                               className="form-control" placeholder={props.t('search')} autoComplete="off"
                                onChange={(e) => setSearch(e.target.value)}
                        />
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </div>
            <div className="table-responsive table-card mt-3 mb-3 p-3" >
                <table className="table table-nowrap table-striped-columns mb-0 text-center w-100"
                       style={{boxShadow: "none", border: "0.5px solid rgba(128, 128, 128, 0.50)", borderBottom: "0"}}>
                    <thead className="table-dark">
                    <tr className="table-nowrap align-content-center text-center">
                        {
                            columns.map((column) => (
                                <th key={column.accessor} scope="col">{column.Header}</th>
                            ))
                        }
                    </tr>
                    </thead>
                    <tbody style={{textAlign: "center"}}>
                    {
                        searchTable && searchTable.map((data, index) => (
                            <tr key={index}>
                                {
                                    columns.map((column) => (
                                        <td key={column.accessor}
                                            style={{
                                                textAlign: "center",
                                                alignContent: "center",
                                                borderColor: 'rgba(128, 128, 128, 0.14)'
                                            }}>
                                            {column.Cell ? column.Cell({
                                                cell: {value: data[column.accessor]},
                                                row: {original: data}
                                            }) : data[column.accessor]}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <nav className="navbar"
                     style={{boxShadow: "none",
                            border: "0.5px solid rgba(128, 128, 128, 0.50)", borderTop: "0"}}>
                    <ul className="pagination justify-content-center mb-0"
                        style={{
                            border: "1px solid rgba(128, 128, 128, 0.27)",
                            borderRadius: "3px",

                        }}>
                        <li className="page-item">
                            <Button
                                className="page-link"
                                style={{borderRight: '1px solid rgba(128, 128, 128, 0.27)', borderRadius: '0'}}
                                onClick={(e) => handlePageChange(e, 'previous')}
                                disabled={data && data.previous ? false : true}>
                                {props.t('prev')}
                            </Button>
                        </li>
                        <li className="page-item">
                                 <span className="page-link">
                                     <b>{currentPage}</b> of <b>{page}</b>
                                </span>
                        </li>
                        <li className="page-item">
                            <Button
                                className="page-link"
                                style={{borderLeft: '1px solid rgba(128, 128, 128, 0.27)', borderRadius: '0'}}
                                onClick={(e) => handlePageChange(e, 'next')}
                                disabled={data && data.next ? false : true}
                            >{props.t('next')}
                            </Button>
                        </li>
                    </ul>
                </nav>
            </div>
            </div>
    );
};

export default OrderTable;