import React, { useEffect, useState } from "react";
import { Input, Label, Card, CardBody, CardHeader, } from "reactstrap";
import { convertDate } from "../../Components/Common/helper";

const OrderInfo = (props) => {
    const [orderData, setOrderData] = useState([]);
    const {data} = props;

    useEffect(() => {
        setOrderData(data);
    }, [data]);


    return (
        <Card>
            <CardHeader>
                <h4 className="card-title">{props.t('orderDetail')}</h4>
            </CardHeader>
            <CardBody>
                <Label for="id">{props.t('partyNo')}</Label>
                <Input 
                    type="text" 
                    name="party_no" 
                    id="party_no" 
                    placeholder="Party No" 
                    value={orderData && orderData.party_no} 
                    style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px'}}
                    readOnly /> 

                <Label for="start_date">{props.t('startDate')}</Label>
                <Input 
                    type="text" 
                    name="started_at" 
                    id="started_at" 
                    placeholder={props.t('startDate')}
                    value={orderData && convertDate(orderData.started_at)} 
                    style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }} 
                    readOnly/>

                <Label for="ended_at">{props.t('finishDate')}</Label>
                <Input 
                    type="text" 
                    name="ended_at" 
                    id="ended_at" 
                    placeholder={props.t('finishDate')}
                    value={orderData && convertDate(orderData.ended_at)} 
                    style={{border: '1px solid #80808047', borderRadius: '3px', backgroundColor: '#cfcfcf73', marginBottom: '10px' }} 
                    readOnly/>

            </CardBody>
        </Card>    
    );
}

export default OrderInfo;