import axios from "axios";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DecryptToken } from "../../../api/helpers/decrypt";

const token = DecryptToken();

async function getUser(){

    const api = new Promise((resolve, reject) => {

        try{
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/user/`,
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                resolve(response.data.results);
            }).catch((error) => {
                reject(error)
            });
        }
        catch (error){
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
      } catch (error) {
        return {
            error: error.message
        }; // Handle the error appropriately or return a default value.
      }
};


const userSlice = createSlice({
    name: 'user',
    initialState: {
      datas: await getUser(), // Initialize with an empty array
    },
    reducers: {},
  });
  export default userSlice.reducer;

