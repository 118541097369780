import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './Components/Hooks/store';
import { Provider } from 'react-redux';
import "./i18n";

const isOnline = navigator.onLine; 


const root = ReactDOM.createRoot(document.getElementById('root'));
// check the internet connection
if (isOnline){
  root.render(
    <Provider store={store}>
      {/* <React.StrictMode> */}
        <App isOnline={isOnline} />
      {/* </React.StrictMode> */}
    </Provider>
  );
}
else {
  root.render(
      <div className="page-content" style={{minHeight:'100vh', overflow:'unset', backgroundColor:'black'}}>
            <div className="text-center text-light">
                    <p className=" fw-bold" 
                        style={{fontSize:'300px', position:'absolute', left:'50%', transform:'translate(-50%, -40%)', zIndex:'0', color:'#878a993d'}}>
                        <i className="mdi mdi-wifi-off"></i>
                    </p>
                <div className='message' style={{marginTop: '10%', zIndex:'100', position:'relative'}}>
                    <h1 className="text-light fw-bolder">NOT CONNECTION</h1>
                    <p className="text-light">You don't have ant internet connection... the sadness :(</p>
                </div>
                 
            </div>
      </div>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
